import * as Yup from 'yup';
import { isValidEmail } from '../../../utils/validation';

export const paymentProviderSchema = (isAccountIdRequired = false) =>
  Yup.object({
    account_id: Yup.string().when([], {
      is: () => isAccountIdRequired,
      then: (schema) => schema.required('is required'),
      otherwise: (schema) => schema.notRequired()
    }),
    api_key: Yup.string().required('is required'),
    api_secret: Yup.string().required('is required'),
    has_api_secret: Yup.boolean().default(false)
  });

export const User = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .required('Email is required')
    .test('isValidEmail', 'Please enter a valid email', (value) => {
      if (!value) return false;
      return isValidEmail(value);
    })
});

export const MerchantPoc = Yup.object({
  phone: Yup.object({
    number: Yup.string()
      .matches(/^[6-9]\d{9}$/, 'Enter a valid phone number')
      .required('Phone number is required'),
    dial_code: Yup.number().positive('Dial code must be a positive number').required('Dial code is required')
  }),
  name: Yup.string().min(2, 'Name must be at least 2 characters long').required('Name is required'),
  email: Yup.string().email('Enter a valid email address').required('Email is required'),
  designation: Yup.string().nullable()
});
