import {
  usePaymentMetricsQuery,
  useCouponPerformanceMetricsQuery,
  usePerformanceMetricsQuery,
  ComparableCouponPerformanceMetrics
} from '../../graphql';
import { DateTime } from 'luxon';
import { Card } from '../../components/flexyui';
import React, { useState, useEffect } from 'react';
import { TotalSales } from '../../components/dashboard/sales/sales';
import ContentWrapper from '../../components/layout/content-wrapper';
import { Title } from '../../components/shared/dashboard-title/title';
import { TotalOrders } from '../../components/dashboard/orders/orders';
import { DatePicker } from '../../components/shared/date-picker/date-picker';
import { AnalyticEvents, EventCategory, trackEvents } from '../../analytics';
import { CouponUsage } from '../../components/dashboard/coupon-usage/coupon-usage';
import { SalesChannels } from '../../components/dashboard/sales-channels/sales-channels';
import { PaymentMethod } from '../../components/dashboard/payment-methods/payment-methods';
import { DatePickerDisplayButton } from '../../components/shared/date-picker/date-picker-display';
import { ConversionFunnel } from '../../components/dashboard/conversion-funnel/conversion-funnel';
import { RevenuePerVisitor } from '../../components/dashboard/revenue-per-visitor/revenue-per-visitor';
import { AverageOrderValue } from '../../components/dashboard/average-order-value/average-order-value';
import { AverageSessionDuration } from '../../components/dashboard/average-session-duration/average-session-duration';

export const Dashboard: React.FC = () => {
  const savedRanges = localStorage.getItem('date-range');
  const [showDateRange, setShowDateRange] = useState(false);
  const [ranges, setRanges] = useState(
    savedRanges
      ? JSON.parse(savedRanges)
      : {
          startDate: DateTime.now()
            .minus({
              days: 7
            })
            .toISODate(),
          endDate: DateTime.now().toISODate()
        }
  );

  const handleCloseDatePicker = () => setShowDateRange(!showDateRange);

  const { data: metricData, loading: metricLoading } = usePerformanceMetricsQuery({
    variables: {
      dateRange: {
        start: new Date(ranges.startDate).toISOString(),
        end: new Date(`${ranges.endDate}T23:59:59.999Z`).toISOString()
      }
    }
  });

  const { data: couponPerformanceData, loading: couponPerformanceLoading } = useCouponPerformanceMetricsQuery({
    variables: {
      dateRange: {
        start: new Date(ranges.startDate).toISOString(),
        end: new Date(`${ranges.endDate}T23:59:59.999Z`).toISOString()
      }
    }
  });

  // const {
  //   data: UpsellingPerformanceData,
  //   loading: UpsellingPerformanceLoading,
  //   refetch: refetchUpsellingPerformanceMetrics
  // } = useUpsellingMetricsQuery({
  //   variables: {
  //     dateRange: {
  //       start: new Date(ranges.startDate).toISOString(),
  //       end: new Date(`${ranges.endDate}T23:59:59.999Z`).toISOString()
  //     }
  //   }
  // });

  const { data: paymentGraphData, loading: loadingPaymentMetric } = usePaymentMetricsQuery({
    variables: {
      dateRange: {
        start: new Date(ranges.startDate).toISOString(),
        end: new Date(`${ranges.endDate}T23:59:59.999Z`).toISOString()
      }
    }
  });

  useEffect(() => {
    localStorage.setItem('date-range', JSON.stringify(ranges));
  }, [ranges]);

  return (
    <>
      <ContentWrapper>
        <div className="mx-4 overflow-x-hidden">
          <Title title="Dashboard">
            <DatePickerDisplayButton
              setShowDateRange={setShowDateRange}
              showDateRange={showDateRange}
              ranges={ranges}
            />
          </Title>

          <div className="mb-4 grid gap-3 sm:gap-2 grid-cols-1 lg:grid-cols-3 sm:grid-cols-2">
            <Card className="col-span-1 sm:col-span-2 row-span-2">
              <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2">
                <TotalSales metricsData={metricData?.performanceMetrics} loading={metricLoading} />
                <AverageOrderValue metricsData={metricData?.performanceMetrics} loading={metricLoading} />
                <RevenuePerVisitor metricsData={metricData?.performanceMetrics} loading={metricLoading} />
              </div>
            </Card>
            <TotalOrders metricsData={metricData?.performanceMetrics} loading={metricLoading} />
            <AverageSessionDuration metricsData={metricData?.performanceMetrics} loading={metricLoading} />
            <ConversionFunnel dateRange={ranges} />
            <CouponUsage
              couponPerformanceData={
                couponPerformanceData?.couponPerformanceMetrics as ComparableCouponPerformanceMetrics
              }
              loading={couponPerformanceLoading}
            />
            {/* <PaymentSuccessRates paymentGraphData={paymentGraphData} loading={loadingPaymentMetric} />*/}
            {/* <PaymentMethod paymentGraphData={paymentGraphData} loading={loadingPaymentMetric} />*/}
            {/* <UpsellingPerformanceMetrics*/}
            {/*  totalOrders={Number(metricData?.metrics?.current?.total_orders)}*/}
            {/*  metrics={UpsellingPerformanceData?.upsellingMetrics?.current}*/}
            {/*  loading={UpsellingPerformanceLoading}*/}
            {/* />*/}
            <SalesChannels dateRange={ranges} />
            <PaymentMethod paymentGraphData={paymentGraphData} loading={loadingPaymentMetric} />
            {/* <PaymentSuccessRates paymentGraphData={paymentGraphData} loading={loadingPaymentMetric} />*/}
          </div>
        </div>
      </ContentWrapper>

      <DatePicker
        open={showDateRange}
        onClose={handleCloseDatePicker}
        setDateRange={(ranges: any) => {
          setRanges(ranges);
          trackEvents(EventCategory.ANALYTICS, AnalyticEvents.DATE_RANGE_CHANGED);
        }}
      />
    </>
  );
};
