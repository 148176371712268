import classNames from 'classnames';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { GetMerchantListQuery } from '../../graphql';
import { CustomAvatar } from '../shared/custom-avatar';
import useLoginWithMerchantId from '../../hooks/use-login-with-merchant';
import { AdministrationEvents, EventCategory, trackEvents } from '../../analytics';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, Input } from '../flexyui';

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
  merchantList: GetMerchantListQuery['getMerchantList'];
};

export const AllStoresDialog: React.FC<Props> = ({ open, setOpen, merchantList }) => {
  const loginMutation = useLoginWithMerchantId();
  const merchantId = useSelector((state: RootState) => state.main.store);

  const [query, setQuery] = useState<string>('');
  const [selectedIndex, setSelectedIndex] = useState(0);

  const updatedMerchantList = merchantList.filter(
    (store) => store.id !== merchantId && store.name.toLowerCase().includes(query.toLowerCase())
  );

  const handleSwitchStore = (merchantId: string) => {
    setOpen(false);
    trackEvents(EventCategory.ADMINISTRATION, AdministrationEvents.STORE_SWITCH);
    loginMutation.mutate(merchantId);
  };

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.key === 'ArrowDown') {
        setSelectedIndex((prev) => (prev + 1) % updatedMerchantList.length);
      } else if (e.key === 'ArrowUp') {
        setSelectedIndex((prev) => (prev === 0 ? updatedMerchantList.length - 1 : prev - 1));
      } else if (e.key === 'Enter') {
        const selectedStore = updatedMerchantList[selectedIndex];
        if (selectedStore) handleSwitchStore(selectedStore.id);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedIndex, updatedMerchantList]);

  return (
    <Dialog open={open} onOpenChange={(value: boolean) => setOpen(value)}>
      <DialogContent className="!gap-0" close={true}>
        <div>
          <DialogHeader>
            <DialogTitle>
              <p>Select Store</p>
            </DialogTitle>
            <div className="sticky z-10 bg-white top-0 p-3 !m-0 border-b-[1px]">
              <Input
                type="text"
                placeholder="Search store"
                className="!m-0"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <DialogDescription className="!p-0 !m-0 text-[#2A324C]">
              <div className="w-full h-[60vh] max-h-[500px] overflow-y-auto scroll-smooth px-2">
                {updatedMerchantList.map((store, index) => (
                  <div
                    key={store.id}
                    onClick={() => handleSwitchStore(store.id)}
                    className={classNames(
                      'flex items-center justify-between p-2 cursor-pointer m-auto my-1 rounded-xl transition-all ease-linear group hover:bg-slate-100',
                      {
                        'bg-slate-100': selectedIndex === index
                      }
                    )}
                  >
                    <div
                      className={classNames(
                        'flex items-center gap-3 group-hover:gap-4 transition-all duration-200 ease-in-out',
                        {
                          'gap-4': selectedIndex === index
                        }
                      )}
                    >
                      <CustomAvatar name={store.name} />
                      <div className="text-sm font-medium">{store.name}</div>
                    </div>
                    <Unicons.UilAngleRight
                      size={20}
                      className={`transition-opacity duration-200 ease-in-out ${
                        selectedIndex === index ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'
                      }`}
                    />
                  </div>
                ))}
                {updatedMerchantList.length === 0 && (
                  <div className="text-gray-500 text-center mt-4">No stores found.</div>
                )}
              </div>
            </DialogDescription>
          </DialogHeader>
        </div>
      </DialogContent>
    </Dialog>
  );
};
