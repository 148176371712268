import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { SideBar } from '../../constants/sidebar';
import * as Unicons from '@iconscout/react-unicons';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, DrawerContent } from '../flexyui/Drawer';
import { setSidebarState } from '../../store/main-slice';
import { SidebarLink } from '../shared/sidebar-link/sidebar-link';
import { ReactComponent as Logo } from '../../assets/images/flexype-logo.svg';

export const Sidebar: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const banners = useSelector((state: RootState) => state.main.banners);
  const sideBarState = useSelector((state: RootState) => state.main.sideBarState);
  const storeUrl = useSelector((state: RootState) => state.main.storeUrl);

  return (
    <>
      <div
        className={classNames('fixed w-56 z-10 border-r-[1px] transition-top duration-300 ease-in-out', {
          'top-20': banners.length > 0,
          'top-12': banners.length === 0
        })}
      >
        <div
          className={classNames('pt-4 hidden lg:flex flex-col justify-between', {
            'h-[calc(100vh-80px)]': banners.length > 0,
            'h-[calc(100vh-48px)]': banners.length === 0
          })}
        >
          <div>
            {SideBar.map((item: any, index) => {
              return <SidebarLink key={index} text={item.name} routeLink={item.to} icon={item.icon} />;
            })}
          </div>
          {storeUrl.length > 0 && (
            <Link
              to={`https://${storeUrl}`}
              target="_blank"
              className="p-2 mx-4 mb-4 flex items-center gap-3 rounded-md my-1 transition-all ease-linear hover:bg-slate-100"
            >
              <div className="h-4">
                <Unicons.UilEye size={18} />
              </div>
              View Store
            </Link>
          )}
        </div>
      </div>

      <Drawer direction="left" open={sideBarState} onOpenChange={(value: boolean) => dispatch(setSidebarState(value))}>
        <DrawerContent anchor="left" className="w-auto">
          <div className="w-[225px] h-full flex flex-col justify-between">
            <div>
              <Logo className="h-8 w-24 my-4 ml-4" />
              {SideBar.map((item: any, index) => {
                return <SidebarLink key={index} text={item.name} routeLink={item.to} icon={item.icon} />;
              })}
            </div>
            {storeUrl.length > 0 && (
              <Link
                to={`https://${storeUrl}`}
                target="_blank"
                className="p-2 mx-4 mb-4 flex items-center gap-3 rounded-md my-1 transition-all ease-linear hover:bg-slate-100"
              >
                <div className="h-4">
                  <Unicons.UilEye size={18} />
                </div>
                View Store
              </Link>
            )}
          </div>
        </DrawerContent>
      </Drawer>
    </>
  );
};
