import React, { useCallback, useEffect, useState } from 'react';
import { Button, Chip, Input, Label, Switch } from '../../flexyui';
import * as Unicons from '@iconscout/react-unicons';
import { AccordionContent, AccordionItem, AccordionTrigger } from '../../flexyui/Accordion';
import { RtoSuitQuery } from '../../../graphql';
import { useFormikContext } from 'formik';
import debounce from 'debounce';
import { ReactComponent as LoadingIcon } from '../../../assets/images/loading.svg';
import useSaveCancelButtons from '../../../hooks/use-save-cancel';
import { enqueueSnackbar } from 'notistack';
import { EventCategory, RtoSuiteEvents, trackEvents } from '../../../analytics';

const FilterUsers = ({
  users,
  loading,
  addedUsers,
  setAddedUsers,
  removedUsers,
  setRemovedUsers,
  loadMoreUsers,
  handleUserSearch
}: any) => {
  const { showActions } = useSaveCancelButtons();
  const { values, setFieldValue } = useFormikContext<RtoSuitQuery>();
  const [phone, setPhone] = useState<string>('');

  const handleAddAndRemoveUsers = (action: string, user?: any) => {
    const phoneNumber = Number(phone).toString().padStart(10, '0');

    if (action === 'add') {
      const isUserPresent = users?.filter((each: any) => each?.phone_number == phoneNumber);
      const isUserPresentInRemovedList = removedUsers?.filter((each: any) => each?.phone_number == phoneNumber);
      if (isUserPresent?.length > 0 && isUserPresentInRemovedList?.length > 0) {
        const updatedRemovedUsers = removedUsers?.filter((each: any) => each?.phone_number != phoneNumber);
        setRemovedUsers(updatedRemovedUsers);
      } else {
        const addUser = { id: null, phone_number: phoneNumber };
        setAddedUsers([addUser, ...addedUsers]);
      }
      trackEvents(EventCategory.RTO_SUITE, RtoSuiteEvents.ADD_USER_FILTER);
    } else {
      const removedUser = users?.filter((each: any) => each?.phone_number === user?.phone_number);
      if (removedUser?.length > 0) {
        setRemovedUsers([...removedUsers, ...removedUser]);
      } else {
        const updatedAddedUsers = addedUsers?.filter((each: any) => each?.phone_number !== user?.phone_number);
        setAddedUsers(updatedAddedUsers);
      }
      trackEvents(EventCategory.RTO_SUITE, RtoSuiteEvents.REMOVE_USER_FILTER);
    }

    if (action == 'add') setPhone('');
  };

  const debouncedSearch = useCallback(debounce(handleUserSearch, 300), [users]);
  useEffect(() => {
    if (phone?.length > 0) debouncedSearch(phone, values);
    else debouncedSearch(Number(null), values);
  }, [phone]);

  const filteredUsers = users.filter(
    (user: any) => !removedUsers.some((removedUser: any) => removedUser.phone_number === user.phone_number)
  );
  const updatedUsersList = [...filteredUsers, ...addedUsers];

  return (
    <>
      <AccordionItem value="item-3" className="pb-2">
        <div className="px-4">
          <AccordionTrigger className="!pb-3">
            <div className="flex flex-col items-start space-y-1">
              <Label size="md" className="text-sm cursor-pointer flex items-center gap-2">
                <div className="py-0.5">Filter based on Users</div>
                {values?.codAvailabilityConfig?.user_filter?.enabled && (
                  <Chip
                    text={values?.codAvailabilityConfig?.user_filter?.enabled ? 'Enabled' : 'Disabled'}
                    variant={values?.codAvailabilityConfig?.user_filter?.enabled ? 'success' : 'draft'}
                  />
                )}
              </Label>
              <Label className="text-[#888D9B] text-left max-w-[95%] sm:max-w-full" size={'sm'}>
                Choose a set phone numbers to exclude from cash on delivery orders.
              </Label>
            </div>
          </AccordionTrigger>
        </div>
        <AccordionContent className="bg-white p-3 border-t border-[#E3E3E3]">
          <div className="flex items-center gap-2 mb-4 ml-0.5">
            <Switch
              checked={values?.codAvailabilityConfig?.user_filter?.enabled}
              onCheckedChange={(value) => {
                setFieldValue('codAvailabilityConfig.user_filter.enabled', value);
                trackEvents(EventCategory.RTO_SUITE, RtoSuiteEvents.USER_FILTER_STATUS_CHANGED);
              }}
              id="codAvailabilityConfig.user_filter.enabled"
            />
            <Label size={'paragraph'} className="text-[#595F74]" htmlFor="codAvailabilityConfig.user_filter.enabled">
              Enable User filtering
            </Label>
          </div>
          <div className="flex items-center gap-2 p-1">
            <Input
              type="number"
              placeholder="Enter or search phone no."
              iconPosition="start"
              icon={<Unicons.UilSearch size={18} />}
              className="w-full sm:w-[500px] -ml-0.5"
              value={phone}
              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                if (showActions && addedUsers?.length === 0 && removedUsers?.length === 0) {
                  enqueueSnackbar('Please save your changes before proceeding with this action.', {
                    variant: 'error'
                  });
                  return;
                }
                const value = e.target.value;
                if (/^\d{0,10}$/.test(value)) setPhone(value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleAddAndRemoveUsers('add');
                }
              }}
              endIcon={loading && <LoadingIcon height={20} className="animate-spin text-[#2F72FF]" />}
            />
            <Button
              variant="outline"
              disabled={phone.length !== 10 || filteredUsers?.find((user: any) => user?.phone_number == Number(phone))}
              className="h-10 hover:border-[#2F72FF] hover:bg-[#f9fcff] border-[1px] rounded-lg font-medium w-[85px]"
              onClick={() => {
                if (showActions && addedUsers?.length === 0 && removedUsers?.length === 0) {
                  enqueueSnackbar('Please save your changes before proceeding with this action.', {
                    variant: 'error'
                  });
                  return;
                }
                handleAddAndRemoveUsers('add');
              }}
            >
              Add
            </Button>
          </div>
          <div className="mt-2 grid grid-cols-2 sm:grid-cols-4  gap-2 w-fit">
            {phone?.length === 10 && !filteredUsers?.find((user: any) => user?.phone_number == Number(phone)) && (
              <div
                key={phone}
                className="flex justify-center gap-2 px-2.5 py-1 bg-[#FAFAFA] text-[#BFBFBF] rounded-2xl border-dashed border border-[#BFBFBF] col-span-2"
              >
                <div>Click 'Add' to add {phone}</div>
              </div>
            )}
            {updatedUsersList?.map((partialCodUser: any) => (
              <div
                key={partialCodUser?.phone_number}
                className="flex items-center justify-between gap-2 px-1.5 py-1 bg-[#D9D9D9] rounded-2xl w-[155px]"
              >
                <Unicons.UilUserCircle size={18} className="text-[#595F74]" />
                <div>{partialCodUser?.phone_number}</div>
                <div
                  className="rounded-[50%] text-[#595F74] cursor-pointer hover:bg-[#EAEAEA]"
                  onClick={() => {
                    if (showActions && addedUsers?.length === 0 && removedUsers?.length === 0) {
                      enqueueSnackbar('Please save your changes before proceeding with this action.', {
                        variant: 'error'
                      });
                      return;
                    }
                    if (!loading) {
                      if (
                        values?.codAvailabilityConfig?.user_filter?.users?.length <
                        values?.codAvailabilityConfig?.user_filter?.user_count
                      )
                        loadMoreUsers(1);
                      handleAddAndRemoveUsers('remove', partialCodUser);
                    }
                  }}
                >
                  <Unicons.UilTimes size={18} />
                </div>
              </div>
            ))}
          </div>
          <div className="mt-2">
            {values &&
            (phone?.length || 0) === 0 &&
            values?.codAvailabilityConfig?.user_filter?.users?.length <
              values?.codAvailabilityConfig?.user_filter?.user_count ? (
              <Button
                disabled={loading}
                variant="link"
                className="w-fit flex items-center text-[#2F72FF] disabled:text-[#BFBFBF] text-sm cursor-pointer hover:no-underline	"
                onClick={() => {
                  if (showActions && addedUsers?.length === 0 && removedUsers?.length === 0) {
                    enqueueSnackbar('Please save your changes before proceeding with this action.', {
                      variant: 'error'
                    });
                    return;
                  }
                  if (!loading) loadMoreUsers();
                }}
              >
                <Unicons.UilAngleDown className="pt-0.5" />
                <div>Load More</div>
              </Button>
            ) : null}
          </div>
        </AccordionContent>
      </AccordionItem>
    </>
  );
};

export default FilterUsers;
