import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  Label,
  Select as SelectCN,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '../../../flexyui';
import * as Unicons from '@iconscout/react-unicons';
import { CollectionSelectionDialog } from '../../../shared/collection-selection/collection-selection';
import SelectedCollections from '../../../shared/selected-collections/selected-collections';
import { indianStates } from '../../../../constants/settings';
import { CollectionType, TaxType, useAddTaxDetailMutation } from '../../../../graphql';
import { enqueueSnackbar } from 'notistack';
import { ReactComponent as LoadingIcon } from '../../../../assets/images/loading.svg';
import { EventCategory, TaxesEvent, trackEvents } from '../../../../analytics';

type Props = {
  refetch: any;
  openProductOverrideModal: boolean;
  setOpenProductOverrideModal: (data: boolean) => void;
};

export const AddOverride: React.FC<Props> = ({ refetch, openProductOverrideModal, setOpenProductOverrideModal }) => {
  const [selectedCollection, setSelectedCollection] = useState<any[]>([]);
  const [collectionSelectionSearch, setCollectionSelectionSearch] = useState('');
  const [openCollectionSelection, setOpenCollectionSelection] = useState<boolean>(false);
  const [overrideRate, setOverrideRate] = useState('');
  const [overrideState, setOverrideState] = useState('DEFAULT');

  useEffect(() => {
    setOpenCollectionSelection(collectionSelectionSearch.length > 0);
  }, [collectionSelectionSearch]);

  const [addTaxDetail, { loading }] = useAddTaxDetailMutation();

  const handleAddTaxDetail = async () => {
    const stateFromCode =
      overrideState === 'DEFAULT'
        ? 'All Regions'
        : indianStates.find((state) => state.state_code === overrideState)?.state;

    try {
      await addTaxDetail({
        variables: {
          input: {
            tax_type: TaxType.Override,
            collection_id: selectedCollection[0]?.collection_id,
            provinces: [
              {
                rate: getRate(),
                state_code: overrideState,
                state: stateFromCode as string
              }
            ],
            country_code: 'IN',
            country_name: 'India'
          }
        }
      });

      setOpenProductOverrideModal(false);
      enqueueSnackbar('Hurray! Override added successfully!', {
        variant: 'success'
      });

      await refetch();
      trackEvents(EventCategory.TAXES_DUTIES, TaxesEvent.ADD_OVERRIDE);
    } catch (error) {
      setOpenProductOverrideModal(false);
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }

    setSelectedCollection([]);
    setOverrideRate('');
    setOverrideState('DEFAULT');
  };

  const getRate = () => {
    const rateAsNumber = parseFloat(overrideRate);
    if (isNaN(rateAsNumber)) {
      return 0;
    }
    return rateAsNumber / 100;
  };

  const handleRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^\d*\.?\d{0,3}$/;
    if (regex.test(value)) {
      setOverrideRate(value);
    }
  };

  return (
    <Dialog open={openProductOverrideModal} onOpenChange={() => setOpenProductOverrideModal(!openProductOverrideModal)}>
      <DialogContent className="!gap-0" size={'sm'} close={true}>
        <div>
          <DialogHeader>
            <DialogTitle className="p-4">
              <p>Add tax override</p>
            </DialogTitle>
            <DialogDescription className="!p-0 !m-0">
              <div className="my-2 px-4 text-[#121b38]">
                <Label size={'paragraph'}>Select a collection to apply the tax override to.</Label>
                <div className="flex items-center gap-2 mt-1 mb-2">
                  <Input
                    type="text"
                    placeholder="Search Collection"
                    iconPosition="start"
                    icon={<Unicons.UilSearch size={18} />}
                    className="w-full sm:w-[473px]"
                    value={collectionSelectionSearch}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                      setCollectionSelectionSearch(e.target.value);
                    }}
                  />
                  <Button
                    variant="outline"
                    className="h-10 hover:border-[#2F72FF] hover:bg-[#f9fcff] border-[1px] rounded-lg font-medium w-[85px]"
                    onClick={() => {
                      setOpenCollectionSelection(true);
                    }}
                  >
                    Browse
                  </Button>
                  <CollectionSelectionDialog
                    key={'CollectionSelection'}
                    singleSelect={true}
                    filterCollectionType={CollectionType.Custom}
                    open={openCollectionSelection}
                    setOpen={(val: boolean) => {
                      setOpenCollectionSelection(val);
                      setCollectionSelectionSearch('');
                    }}
                    search={collectionSelectionSearch}
                    preSelections={selectedCollection}
                    onSave={(value: any) => {
                      setSelectedCollection(value);
                    }}
                  />
                </div>
                <SelectedCollections value={selectedCollection} singleSelect={true} />
                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div>
                    <Label size={'paragraph'}>Location</Label>
                    <SelectCN
                      value={overrideState}
                      onValueChange={(value) => {
                        setOverrideState(value);
                      }}
                      defaultValue="DEFAULT"
                    >
                      <SelectTrigger className="h-10 mt-1 w-full">
                        <div className="w-[225px] overflow-hidden overflow-ellipsis whitespace-nowrap text-left">
                          <SelectValue placeholder="'Select State" />
                        </div>
                      </SelectTrigger>
                      <SelectContent side="bottom" longList={true}>
                        {/* eslint-disable-next-line camelcase */}
                        {indianStates.map(({ state_code, state }) => (
                          // eslint-disable-next-line camelcase
                          <SelectItem key={state_code} value={state_code}>
                            {state}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </SelectCN>
                  </div>
                  <div>
                    <Label size={'paragraph'}>Tax rate</Label>
                    <Input
                      value={overrideRate}
                      onChange={handleRateChange}
                      type="number"
                      iconPosition="end"
                      icon={<Unicons.UilPercentage size={18} className="mt-1" />}
                      className="mt-1"
                    />
                  </div>
                </div>
              </div>
              <div className="mb-4" />
            </DialogDescription>
          </DialogHeader>
        </div>
        <DialogFooter>
          <Button
            variant={'outline'}
            size={'md'}
            onClick={() => {
              setOpenProductOverrideModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant={'default'}
            size={'sm'}
            className="!w-[127px]"
            onClick={async () => {
              await handleAddTaxDetail();
            }}
            disabled={
              !selectedCollection.length ||
              !overrideRate.length ||
              !overrideState.length ||
              parseFloat(overrideRate) < 0 ||
              parseFloat(overrideRate) > 100
            }
          >
            {loading ? <LoadingIcon height={20} className={'animate-spin text-white'} /> : 'Add override'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
