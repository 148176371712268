import classNames from 'classnames';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { setBanners } from '../../store/main-slice';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import React, { CSSProperties, useEffect } from 'react';
import { DashboardBannerConfig, DashboardBannerType, useDashboardConfigQuery } from '../../graphql';

import DefaultBannerBg from '../../assets/images/default-banner-bg.svg';

const DEFAULT_STYLES = {
  main: { color: '#ffffff', backgroundColor: '#4ca49a' },
  action: { color: '#4ca49a', backgroundColor: '#ffffff' }
};

export const Banner = () => {
  const { data } = useDashboardConfigQuery();
  const dispatch = useDispatch<AppDispatch>();
  const banners = useSelector((state: RootState) => state.main.banners);

  useEffect(() => {
    if (data) dispatch(setBanners(data?.dashboardConfig?.banners || []));
  }, [data]);

  const getBannerStyles = (banner: DashboardBannerConfig) => {
    switch (banner.type) {
      case DashboardBannerType.Feature:
        return DEFAULT_STYLES;
      case DashboardBannerType.Info:
        return {
          main: { color: '#002133', backgroundColor: '#91d0ff' },
          action: { color: '#91d0ff', backgroundColor: '#002133' }
        };
      case DashboardBannerType.Warning:
        return {
          main: { color: '#251a00', backgroundColor: '#ffb800' },
          action: { color: '#ffb800', backgroundColor: '#251a00' }
        };
      case DashboardBannerType.Critical:
        return {
          main: { color: '#ffffff', backgroundColor: '#c70a24' },
          action: { color: '#c70a24', backgroundColor: '#ffffff' }
        };
      case DashboardBannerType.Custom: {
        const textColor = banner.ui_props?.text_color;
        const backgroundColor = banner.ui_props?.background_color;
        const actionTextColor = banner.primary_action?.text_color;
        const actionBackgroundColor = banner.primary_action?.background_color;

        if (textColor && backgroundColor && actionTextColor && actionBackgroundColor) {
          return {
            main: {
              color: textColor || '#ffffff',
              backgroundColor: backgroundColor || '#4ca49a'
            },
            action: {
              color: actionTextColor || '#4ca49a',
              backgroundColor: actionBackgroundColor || '#ffffff'
            }
          };
        }
        return DEFAULT_STYLES;
      }
      default:
        return DEFAULT_STYLES;
    }
  };

  if (banners.length === 0) return <></>;

  const banner = banners[0] as DashboardBannerConfig;
  const style = getBannerStyles(banner);

  return (
    <div
      className={classNames(
        'fixed top-0 z-10 w-screen text-[12px] py-0.5 flex items-center justify-center whitespace-nowrap transition-[height,opacity] duration-300 ease-in-out',
        {
          'h-8 opacity-100 overflow-visible': banners.length > 0,
          'h-0 opacity-0 overflow-hidden': banners.length === 0
        }
      )}
      style={{
        ...(banner.type === DashboardBannerType.Custom &&
          !!banner?.ui_props?.background_image && {
            backgroundImage: `url(${banner.ui_props.background_image})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }),
        ...(style.main as CSSProperties)
      }}
    >
      <div className="flex items-center md:justify-center md:overflow-visible md:whitespace-normal animate-marquee md:animate-none">
        <div>
          <ReactMarkdown>{banner.title}</ReactMarkdown>
        </div>
        {banner.primary_action?.url && banner.primary_action?.content && (
          <Link
            to={banner.primary_action.url}
            target={banner.primary_action.target === 'BLANK' ? '_blank' : '_self'}
            className="h-5 text-xs w-fit px-2 ml-1.5 rounded font-normal flex items-center"
            style={style.action as CSSProperties}
          >
            {banner.primary_action.content}
          </Link>
        )}
      </div>
    </div>
  );
};
